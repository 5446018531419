import React from 'react'
import { Link as GLink } from 'gatsby'
import { Container, Box, Flex, Text } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `#F7F8FC`,
    paddingTop: '1rem'
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, `flex-start`],
    py: 5
  },
  links: {
    display: 'flex',
      '@media (max-width: 991px)': {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      '@media (max-width: 639px)': {
        marginLeft: '-90px'
      },
  }
}

const FooterBlock01 = ({ content: { images, collection } }) => {
  return (
    <Box sx={styles.wrapper}>
      <Container px='4'>
        <Flex sx={styles.footer}>
          <Box sx={{ minWidth: 200 }}>
            <Box mb='2' mt={[4, 0]} pb="4">
              <GLink to='/'>
                <ContentImages content={{ images }} imageEffect='fadeIn' />
              </GLink>
            </Box>
            <Box pt='2' mb={[2, 4]}>
              <Text  sx={{
                fontSize: 15,
                color: '#2A205E',
              }}>
                Copyright { new Date().getFullYear() } EDUCABOT <br />
                Todos los derechos reservados
              </Text>
            </Box>
          </Box>
          <Box sx={styles.links}>
            {collection?.map(
              ({ text, buttons }, index) =>
                buttons && (
                  <Box key={`item-${index}`} mb='3'>
                    <ContentText
                      content={text?.[0]}
                      variant='h5'
                      sx={{ display: [`none`, `block`],
                            marginRight: '6rem',
                            borderBottom: '2px solid #2A205E'
                          }}
                    />
                    <ContentButtons
                      content={buttons}
                      variant='vertical'
                      wrapperStyles={{ flexDirection: ['column', null, null, `column`] }}
                    />
                  </Box>
                )
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

FooterBlock01.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock01)
