import React from 'react'
import mergeWith from 'lodash.mergewith'
import SVG from 'react-inlinesvg'
import { Box, css } from 'theme-ui'

const styles = {
  button: {
    width: '30px'
  }
}

const ContentIcon = ({ content, round, p, ...props }) => {
  if (!content) return null

  const { src, ...contentRest } = content

  if (!src) return null

  const mergedProps = mergeWith({}, props, contentRest, (a, b) =>
    b === null ? a : undefined
  )

  return (
    <Box
      sx={{
        display: `inline-block`,
        verticalAlign: `middle`,
        borderRadius: round ? `full` : `lg`,
        minWidth: null,
        bg: mergedProps.bg
      }}
      p={mergedProps.bg && p}
      {...props}
    >
      <SVG
        src={src}
        css={css({
          fill: mergedProps.color,
          size: `icon.${mergedProps.size || 'md'}`,
          display: `block`,
          transition: 'all .2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.1)',
            '&>path': {
              fill: '#2A205E'
            },
          }
        })}
      />
    </Box>
  )
}

ContentIcon.defaultProps = {
  p: 3,
  round: false
}

export default ContentIcon
