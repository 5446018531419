import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import Reveal from '@solid-ui-components/Reveal'
import ListItem from '@solid-ui-components/ListItem'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    flex: `1 1 0`,
    minWidth: 300,
    p: 3,
    textAlign: 'center'
  },
  itemDescription: {
    flexBasis: `3/5`,
    flexGrow: 1,
    order: [1, null, 0],
    mb: 3
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    paddingBottom: '12px',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'center'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    fontSize: '18px',
    '@media (max-width: 765px)': {
      textAlign: 'center'
    },
  }
}

const FeaturesBlock05 = ({ content: { text, collection } }) => (
  <Container as={Reveal}>
    <Box sx={{ textAlign: `center`, maxWidth: '100%' }}>
      <Text sx={styles.title}> Pilares del pensamiento computacional </Text>
      <Text sx={styles.subTitle}>Las nuevas tecnologías requieren que los estudiantes adquieran habilidades para resolver problemas utilizando sistemas basados en computadoras, más conocida como “pensamiento computacional”, una metodología basada en cuatro pilares fundamentales:</Text>
    </Box>
    {collection && (
      <>
        <Divider />
        <Flex sx={{ flexWrap: `wrap` }} m={-3}>
          {collection.map(
            ({ text, icon, collection, buttons, container }, index) => (
              <Reveal
                key={`item-${index}`}
                effect='fadeInGrow'
                delay={0.15 * (index + 1)}
                css={css(styles.listItem)}
              >
                <ContentContainer
                  content={container}
                  variant='cards.paper'
                  sx={{ height: `full` }}
                >
                  <Icon content={icon} mb='3' round />
                  <ContentText content={text?.[0]} />
                  <Flex sx={{ alignItems: `center`, flexWrap: `wrap` }}>
                    <ContentText
                      content={text?.slice(1)}
                      sx={styles.itemDescription}
                      mt={[3, null, 0]}
                    />
                    {collection && (
                      <Box sx={{ flexGrow: 1, mr: [3, null, 0] }}>
                        {collection.map((props, index) => (
                          <ListItem key={`item-${index}`} {...props} compact />
                        ))}
                      </Box>
                    )}
                  </Flex>
                  {buttons && (
                    <>
                      <Divider space={3} />
                      <ContentButtons content={buttons} />
                    </>
                  )}
                </ContentContainer>
              </Reveal>
            )
          )}
        </Flex>
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesBlock05)
