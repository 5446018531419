import React, { useContext } from 'react'
import { Flex, Box } from 'theme-ui'
import {Helmet} from "react-helmet";
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Modal from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import { TabsContext } from '@solid-ui-components/Tabs'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentMap from '@solid-ui-components/ContentMap'
import ContentForm from '@solid-ui-components/ContentForm'
import { ModalContext } from '@solid-ui-components/Modal'

const styles = {
  modalContainer: {
    '@media (max-width: 1550px)': {
      maxHeight: '93vh'
    },
    '@media (max-width: 765px)': {
      height: '100%'
    },
  },
  contentModal: {
    flex: 1, 
    py: [3, 4], 
    px: [3, 5]
  },
  modalContact: {
    display: [`none`, null, `block`],
    flex: 1,
    minWidth: [null, null, null, 500],
    height: 600,
    position: `relative`,
    overflow: `hidden`,
    bg: '#01CEAA',
    backgroundImage: `url(${bgContact})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    '@media (max-width: 1920px)': {
      height: 635
    }
  }
}


import bgContact from '../../../../../../../../site/content/assets/flexiblocks/homepage/bgContactGreen.png'
import { FormContact } from '../../../../../../solid-ui-components/src/FormContact/FormContact';

const ModalBlock01 = ({ content, reverse }) => {
  const { activeModal } = useContext(ModalContext)
  const {
    activeTab: { index = 0 }
  } = useContext(TabsContext)

  const { identifier, text, buttons } = content

  if (activeModal && activeModal !== identifier) return null

  const images = content?.collection[index]?.images || content.images
  const map = content?.collection[index]?.map || content.map
  const hasMedia = images || map

  const webformData = 'https://webforms.pipedrive.com/f/6xQPD9rusrq013g24dwCQZVKcxMgJ1PgN3q02DPIhGZl3YzOD0eAEtZxuPfo9x9ZGH'

  return (
    <Modal
        id={identifier}
        contentStyles={{
          maxWidth: hasMedia ? `narrow` : 500,
          p: 0
        }}
      >
      <Box sx={styles.modalContainer}>
        <Flex
          sx={{
            alignItems: `stretch`,
            flexDirection: [
              reverse ? `column-reverse` : `column`,
              reverse ? `row-reverse` : `row`
            ]
          }}
        >
          {hasMedia && (
            <Box
              sx={styles.modalContact}>
              {images && (
                <ContentImages
                  content={{ images }}
                  imageFit='cover'
                  height='100%'
                  reverse={reverse}
                />
              )}
              {map && (
                <Reveal
                  effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                  style={{ width: `100%`, height: `100%` }}
                >
                  <ContentMap content={map} />
                </Reveal>
              )}
            </Box>
          )}
          {Array.isArray(content.collection) && (
            <Box sx={styles.contentModal}>
              <ContentText content={text} space={3} />
              <Box space={3} id={identifier}>
                {content.collection
                  ?.filter(Boolean)
                  ?.map(({ text, collection, form }, index) => (
                    <Reveal
                      key={`item-${index}`}
                      effect='fadeIn'
                      content={{ text }}
                    >
                      {text && (
                        <>
                          <Box sx={{ textAlign: `center`, fontWeight: 600 }}>
                            <ContentText content={text?.slice(1)} />
                          </Box>
                        </>
                      )}
                      {collection?.map((props, index) => (
                        <Box sx={{ padding: '15px 0 0 40px', fontWeight: 600 }}>
                          <ListItem key={`item-${index}`} {...props}compact />
                        </Box>
                      ))}
                      <Divider space={3} />
                        <FormContact webformData={webformData} />
                      {/* {form && (
                        <ContentForm
                        form={form}
                        id={`form.${identifier}.${
                          form.multiStep ? 'multi' : index
                        }`}
                        />
                      )} */}
                    </Reveal>
                  ))}
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
      </Modal>
  )
}

export default ModalBlock01
