import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01/Block01'
import ModalContactSmartTeam from '@solid-ui-blocks/Educabot/SmartTeam/Modal/Block01/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import HeroOne from '@solid-ui-blocks/Educabot/SmartTeam/Hero/Block03'
import Features from '@solid-ui-blocks/Educabot/SmartTeam/Features/features'
import Contact from '@solid-ui-blocks/Educabot/SmartTeam/Contact'
import favicon from '../../../../../../site/content/assets/favicon.png'

import Books from '@solid-ui-blocks/Educabot/SmartTeam/Hero/books/Block05'
import Platforms from '@solid-ui-blocks/Educabot/SmartTeam/Hero/platforms/Block05'
import Robotic from '@solid-ui-blocks/Educabot/SmartTeam/Hero/robotic/Block05'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalContactSmartTeam content={content['contact-smart-team']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.heroOne}>
        <HeroOne content={content['heroOneSmart']} reverse />
      </Box>
      <Box sx={styles.books}>
        <Box sx={{ textAlign: `center`, maxWidth: '90%', margin: 'auto' }}>
          <Text sx={styles.title}> Una solución integral para tu escuela </Text>
          <Text sx={styles.subTitle}>Smart TEAM es el primer programa integral de tecnología para los alumnos de tu escuela que incluye la <strong>pedagogía</strong> en libros, la <strong>programación</strong> en softwares de última tecnología y la robótica en kits únicos. </Text>
        </Box>
        <Books content={content['books']} reverse />
      </Box>
      <Box sx={styles.sections}>
        <Platforms content={content['platforms']} />
      </Box>
      <Box sx={styles.robotic}>
        <Robotic content={content['robotic']} reverse />
      </Box>
      <Box sx={styles.features}>
        <Features content={content['pillars-of-thought']} />
      </Box>
      <Box sx={styles.contact}>
        <Contact content={content['formContactSolutions']} reverse />
      </Box>
      <Footer content={content['footer']} />


    </Layout>
  )
}

export const query = graphql`
  query solutionsSmartTeamBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
