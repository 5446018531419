/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import bgContact from '../../../../../../site/content/assets/flexiblocks/homepage/education/bg-contact.svg'

export default {
  header: {
    bg: '#27A8FF',
    pt: '5rem',
  },
  heroOne: {
    bg: '#FFFFFF',
    pt: '3rem',
    pb: '5rem',
    '@media (max-width: 991px)': {
      pt: 0,
      pb: '2rem',
    },
  },
  features: {
    pt: '3rem',
    pb: '5rem'
  },
  contact: {
    bg: '#27A8FF',
    backgroundImage: `url(${bgContact})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: 'contain',
    '@media (max-width: 765px)': {
      backgroundSize: 'cover',
      paddingBottom: '3rem',
    },
    '@media (max-width: 625px)': {
       paddingBottom: '8rem',
     },
     '@media (max-width: 485px)': {
       paddingBottom: '11rem',
     }
  },
  books: {
    bg: '#FFFFFF',
    '@media (max-width: 991px)': {
      pt: '3rem'
    }
  },
  sections: {
    pt: '2rem',
    bg: '#FFFFFF'
  },
  robotic: {
    pt: '5rem',
    pb: '5rem',
    bg: '#FFFFFF'
  },
  title: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    pt: '3rem',
    pb: '2rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'left'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    fontSize: '18px',
    width: '85%',
    margin: 'auto',
    '@media (max-width: 765px)': {
      textAlign: 'left',
      width: '100%',
      pb: '2rem'
    },
  }
}
